import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO({
  description,
  image,
  lang,
  meta,
  pathname,
  keywords,
  title,
  type,
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        const root = data.site.siteMetadata.url
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s — ${data.site.siteMetadata.title}`}
          >
            <meta name="description" content={metaDescription} />
            <meta name="og:title" content={title} />
            <meta name="og:description" content={metaDescription} />
            <meta name="og:url" content={`${root}${pathname || ''}`} />
            <meta name="og:type" content={type || 'website'} />
            {image && image.src && (
              <meta name="og:image" content={image && `${root}${image.src}`} />
            )}
            <meta name="twitter:card" content="summary" />
            {data.site.siteMetadata.twitter && (
              <meta
                name="twitter:creator"
                content={data.site.siteMetadata.twitter}
              />
            )}
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={metaDescription} />
            {keywords && keywords.length > 0 && (
              <meta name="keywords" content={keywords.join(', ')} />
            )}
            <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        twitter
        url
      }
    }
  }
`
