import { grid } from 'styled-components-grid'
import styled from 'styled-components'

export default styled.p`
  ${grid.unit({
    size: {
      xs: 1,
      sm: 4 / 5,
    },
  })}

  text-align: right;

  color: ${props => props.theme.colors.footer.text};

  a {
    :after {
      content: '|';
      text-decoration: none;
      padding-left: ${props => props.theme.dimen.footer.x};
      padding-right: ${props => props.theme.dimen.footer.x};
      display: inline-block;
    }
    :hover {
      text-decoration: underline;
    }
    color: ${props => props.theme.colors.footer.text};
    text-decoration: none;
  }

  font-size: ${props => props.theme.font._.footnote.size};

  ${props => props.theme.media.sm`
    font-size: ${props => props.theme.font.sm.footnote.size};
  `}

  ${props => props.theme.media.md`
    font-size: ${props => props.theme.font.md.footnote.size};
  `}
`
