import React, { Component } from 'react'
import styled from 'styled-components'

import { Link } from 'gatsby'

import Diptych from './diptych'
import Info from './info'
import Legal from './legal'
import Line from './line'
import Logo from './logo'

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sent: false,
      message: '',
    }
  }

  render() {
    return (
      <footer id="contact" className={this.props.className}>
        <Line>
          <h1>{this.props.title || 'Contact'}</h1>
          <p>{this.props.headline}</p>
        </Line>

        <Diptych>
          <Info>
            <p>{this.props.lead}</p>

            <div>
              <section>
                <h2>{this.props.emailTitle || 'E-mail'}</h2>
                <p>{this.props.email}</p>
              </section>

              <section>
                <h2>{this.props.addressTitle || 'Address'}</h2>
                <address>
                  <p>{this.props.address}</p>
                  <p>
                    {this.props.postalcode} {this.props.city}
                  </p>
                  <p>{this.props.country}</p>
                </address>
              </section>
            </div>
          </Info>
        </Diptych>

        <Diptych>
          <Logo src={this.props.logo} />
          <Legal>
            {(this.props.links || []).map((link, id) => {
              return (
                <Link key={id} to={link.url}>
                  {link.label}
                </Link>
              )
            })}
            <span>
              &copy; {this.props.copyright} {this.props.company}
            </span>
          </Legal>
        </Diptych>
      </footer>
    )
  }
}

export default styled(Footer)`
  background: ${props => props.theme.colors.footer.bg};

  padding-top: ${props => props.theme.dimen.banner._.top};
  padding-bottom: ${props => props.theme.dimen.banner._.bottom};

  ${props => props.theme.media.md`
    padding-top: ${props => props.theme.dimen.banner.md.top};
  `}

  ${props => props.theme.media.lg`
    padding-top: ${props => props.theme.dimen.banner.lg.top};
  `}
`
