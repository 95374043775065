import styled from 'styled-components'

export default styled.div`
  display: block;
  float: right;
  margin-left: 13px;
  z-index: 9999;
  height: 22px;
  width: 22px;
  position: relative;

  :hover {
    cursor: pointer;
  }

  ${props => props.theme.media.xs`
     margin-left: 25px;
  `};

  ${props => props.theme.media.lg`
     display: none;
  `};

  div {
    width: 22px;
    height: 4px;
    border-radius: 1px;
    transition: 0.4s;
    background-color: #000;
    position: absolute;

    &:first-child {
      top: 1px;
      transform: rotate(0);
    }
    &:nth-child(2) {
      top: 9px;
    }

    &:last-child {
      top: 17px;
      transform: rotate(0);
    }
  }

  &.isOpen {
    div:first-child {
      top: 9px;
      transform: rotate(45deg);
    }

    div:nth-child(2) {
      top: 9px;
      opacity: 0;
    }

    div:last-child {
      top: 9px;
      transform: rotate(-45deg);
    }
  }
`
