import React, { Component } from 'react'
import { Link } from 'gatsby'

import Container from './container'
import Hamburger from './hamburger'
import Logo from './logo'
import Menu from './menu'
import MobileMenu from './mobile-menu'
import MobileNav from './mobile-nav'
import Navbar from './navbar'

export default class Header extends Component {
  state = {
    expanded: false,
  }

  handleOpenMenu = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  hideMobileNavbar = () => {
    this.setState({ expanded: false })
  }

  render() {
    const links = this.props.links.map((link, i) => (
      <li key={`main-nav__${i}`} className={link.containerClass}>
        <Link to={link.path} activeClassName="active">
          {link.name}
        </Link>
      </li>
    ))
    return (
      <Container>
        <Navbar>
          <Logo>
            <Link to="/" aria-label="home">
              <img
                src={this.props.logo}
                alt={(this.props.company || 'Company') + ' logo'}
              />
            </Link>
          </Logo>
          <Menu>
            <ul>{links}</ul>
          </Menu>
          <MobileNav>
            <Hamburger
              onClick={this.handleOpenMenu}
              className={this.state.expanded ? 'isOpen' : ' '}
            >
              <div />
              <div />
              <div />
            </Hamburger>
          </MobileNav>
          <MobileMenu
            className={
              this.state.expanded ? 'menu-mobile isOpen' : 'menu-mobile'
            }
          >
            <ul onClick={this.hideMobileNavbar}>{links}</ul>
          </MobileMenu>
        </Navbar>
      </Container>
    )
  }
}
