import { grid } from 'styled-components-grid'
import styled from 'styled-components'

export default styled.div`
  ${grid({})}

  justify-content: space-between;
  align-items: top;

  padding-top: ${props => props.theme.dimen.line.y};
  padding-bottom: ${props => props.theme.dimen.line.y};
  padding-right: ${props => props.theme.dimen.line.x};
  padding-left: ${props => props.theme.dimen.line.x};

  ${props => props.theme.media.xl`
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    width: ${props => props.theme.breakpoints.xl}px;
  `}
`
