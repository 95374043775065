import styled from 'styled-components'
import { grid } from 'styled-components-grid'

export default styled.div`
  ${grid.unit({
    size: 4 / 5,
    visible: {
      xs: false,
      lg: true,
    },
  })}

  ul {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    font-size: ${props => props.theme.font._.menu.size};
    line-height: ${props => props.theme.font._.menu.lineHeight};

    ${props => props.theme.media.sm`
      font-size: ${props => props.theme.font.sm.menu.size};
      line-height: ${props => props.theme.font.sm.menu.lineHeight};
    `};

    li {
      margin-right: 70px;
      a {
        text-decoration: none;
        color: ${props => props.theme.colors.navbar.link};
        cursor: pointer;

        &.active {
          font-weight: ${props => props.theme.fontWeights.strong};
        }
      }
    }

    li:last-child {
      margin: 0;
    }

    .link-button {
      a {
        display: inline-block;
        height: 100%;
        padding: 9px 10px;
        margin: 0;
        border: 2px solid ${props => props.theme.colors.foreground('action')};
        color: ${props => props.theme.colors.foreground('action')};
        font-weight: ${props => props.theme.fontWeights.strong};
        transition: all 0.15s ease-in;

        ${props => props.theme.media.sm`
          padding: 20px 57px;
        `};

        &:hover {
          color: ${props => props.theme.colors.foreground('actionHover')};
          background-color: ${props =>
            props.theme.colors.background('actionHover')};
        }
      }
    }
  }
`
