module.exports = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  },
  pageLength: 12,
  paginatorWidth: 2,
  colors: {
    background: '#ff5583',
    foreground: '#1e1e1e',
  },
  name: 'asabina-gmbh-website',
  shortName: 'asabina-website',
  icon: 'src/images/logotype-on-transparent.svg',
  title: 'Asabina',
  url: "https://www.asabina.de",
  description: 'Consulting and engineering practice specialized in cloud computing, electronics engineering and machine learning.',
  organization: {
    name: 'Asabina GmbH',
    address: 'Paul-Lincke Ufer 7B',
    postalcode: '10999',
    city: 'Berlin',
    country: 'Germany',
    email: 'info at asabina dot de',
    twitter: '@asabinahq',
  },
  copyright: '2020',
  footer: {
    headline: 'Interested in building innovative tech?',
    lead: "Send us an e-mail so we can help you figure out how to get tech done.",
  },
  logos: {
    header: './src/assets/svg/logo.svg',
    footer: './src/assets/svg/logo-white.svg',
  },
  articles: {
    title: () => 'Articles',
    prefix: () => 'posts',
    pathRegex: () => '/posts/',
    indexKeywords: ['index', 'posts', 'toc'],
    breadcrumbLabel: 'Articles',
  },
  tags: {
    title: (tag) => tag ? `Articles re: ${tag}` : 'Article Tags',
    prefix: (tag) => tag ? `posts/tags/${tag}` : `posts/tags`,
    indexKeywords: (tag) => tag ? [tag, 'tags', 'index', 'posts'] : ['tags', 'index', 'posts'],
    breadcrumbLabel: 'Article tags',
  },
  links: {
    header: [
      {
        name: 'Home',
        path: '/',
      },
      /*
      {
        name: 'Articles',
        path: '/posts/',
      },
      */
      {
        name: 'Services',
        path: '/services/',
      },
      {
        containerClass: 'link-button',
        name: 'Contact us',
        path: '#contact',
      },
    ],
    footer: [
      {
        label: 'Impressum',
        url: '/legal/impressum',
      },
    ],
  }
}
