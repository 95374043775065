import { grid } from 'styled-components-grid'
import styled from 'styled-components'

export default styled.div`
  ${grid({})}

  justify-content: space-between;
  align-items: center;

  padding-top: ${props => props.theme.dimen.banner.top};
  padding-bottom: ${props => props.theme.dimen.line.y};
  padding-right: ${props => props.theme.dimen.line.x};
  padding-left: ${props => props.theme.dimen.line.x};

  ${props => props.theme.media.xl`
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    width: ${props => props.theme.breakpoints.xl}px;
  `}

  h1 {
    ${grid.unit({ size: 1 })}

    color: ${props => props.theme.colors.footer.text};
    font-size: ${props => props.theme.font._.h1.size};
    font-weight: ${props => props.theme.fontWeights.strong};
    padding-bottom: ${props => props.theme.dimen.line.y};
    padding-top: ${props => props.theme.dimen.line.y};
    text-transform: uppercase;

    ${props => props.theme.media.sm`
      font-size: ${props => props.theme.font.sm.h1.size};
    `}

    ${props => props.theme.media.md`
      font-size: ${props => props.theme.font.md.h1.size};
    `}
  }

  p {
    ${grid.unit({ size: 1 })}

    color: ${props => props.theme.colors.footer.highlight};
    font-size: ${props => props.theme.font._.h2.size};
    font-weight: ${props => props.theme.fontWeights.strong};
    line-height: ${props => props.theme.font._.h2.lineHeight};
    padding-bottom: ${props => props.theme.dimen.line.y};
    padding-top: ${props => props.theme.dimen.line.y};

    ${props => props.theme.media.sm`
      font-size: ${props => props.theme.font.sm.h2.size};
      line-height: ${props => props.theme.font.sm.h2.lineHeight};
      max-width: 75%;
    `}

    ${props => props.theme.media.md`
      font-size: ${props => props.theme.font.md.h2.size};
      line-height: ${props => props.theme.font.md.h2.lineHeight};
      max-width: 50%;
    `}
  }
`
