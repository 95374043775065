import { css } from 'styled-components'

import { breakpoints } from '@parameters'
import fonts from '@fonts'

const colorPink = '#ff5583'
const colorBlack = '#000'
const colorOpaqueBlack = 'rgba(0, 0, 0, 0.5)'
const colorWhite = '#fff'
const colorOpaqueWhite = 'rgba(255,255,255,0.5)'
const colorDarkGray = '#1e1e1e'
const colorMidGray = '#a1a1a1'
const colorLightGray = '#e4e4e4'
const colorTransparent = 'none'

const background = color => {
  switch (color) {
    case 'black':
      return colorBlack
    case 'dark':
      return colorDarkGray
    case 'mid':
      return colorMidGray
    case 'light':
      return colorLightGray
    case 'white':
    case 'action':
      return colorWhite
    case 'opaqueWhite':
      return colorOpaqueWhite
    case 'black':
      return colorBlack
    case 'opaqueBlack':
      return colorOpaqueBlack
    case 'accent':
    case 'actionHover':
      return colorPink
    default:
      return colorTransparent
  }
}

const foreground = color => {
  switch (color) {
    case 'dark':
      return colorDarkGray
    case 'light':
      return colorMidGray
    case 'white':
      return colorWhite
    case 'accent':
    case 'action':
      return colorPink
    case 'actionHover':
      return colorBlack
    default:
      return colorBlack
  }
}

const fontStyle = `
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 100;
    src:
      url(${fonts.Montserrat100WOFF2}) format("woff2"),
      url(${fonts.Montserrat100WOFF}) format("woff");
    unicode-range: U+000-5FF;
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    src:
      url(${fonts.Montserrat300WOFF2}) format("woff2"),
      url(${fonts.Montserrat300WOFF}) format("woff");
    unicode-range: U+000-5FF;
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    src:
      url(${fonts.Montserrat500WOFF2}) format("woff2"),
      url(${fonts.Montserrat500WOFF}) format("woff");
    unicode-range: U+000-5FF;
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    src:
      url(${fonts.Montserrat700WOFF2}) format("woff2"),
      url(${fonts.Montserrat700WOFF}) format("woff");
    unicode-range: U+000-5FF;
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 900;
    src:
      url(${fonts.Montserrat900WOFF2}) format("woff2"),
      url(${fonts.Montserrat900WOFF}) format("woff");
    unicode-range: U+000-5FF;
  }
`

const theme = {
  breakpoints,
  media: Object.keys(breakpoints).reduce((acc, label) => {
    acc[label] = (...args) => css`
      @media (min-width: ${breakpoints[label]}px) {
        ${css(...args)};
      }
    `
    return acc
  }, {}),
  logo: {
    maxHeight: '30px',
    minHeight: '20px',
  },

  colors: {
    background,
    foreground,

    aside: {
      background: background('light'),
      text: foreground('black'),
    },

    form: {
      input: foreground('dark'),
      placeholder: foreground('light'),
    },

    navbar: {
      link: foreground('black'),
      color: foreground('black'), // TODO: rename to text
      other: background('opaqueBlack'),
      background: background('white'),
    },

    footer: {
      bg: background('black'),
      text: foreground('light'),
      highlight: foreground('white'),
    },

    nav: {
      bg: background('light'),
      text: foreground('black'),
    },
  },
  dimen: {
    main: {
      x: '1rem',
      y: '1rem',
    },
    blockquote: {
      marginTop: '1rem',
      side: '1rem',
      borderWidth: '1rem',
      borderColor: foreground('accent'),
    },
    line: {
      x: '1rem',
      y: '1rem',
    },
    banner: {
      _: {
        top: '3rem',
        bottom: '3rem',
        headerBottom: '1.5rem',
      },
      md: {
        top: '3rem',
        bottom: '3rem',
        headerBottom: '2rem',
      },
      lg: {
        top: '7rem',
        bottom: '7rem',
        headerBottom: '2rem',
      },
    },
    breadcrumb: {
      x: '1rem',
      y: '1rem',
      bottomMargin: '0.5rem',
      section: '2rem',
      half: '1.25rem',
      minimumSide: '3rem',
    },
    card: {
      image: {
        height: '12rem',
      },
    },
    tag: {
      x: '0.5rem',
      y: '0.5rem',
    },
    articleTitle: {
      top: '1rem',
      bottom: '1rem',
      right: 0,
      left: '2px',
    },
    articlePreamble: {
      top: 0,
      bottom: '1rem',
      right: 0,
      left: '2px',
    },
    footer: {
      x: '0.5rem',
      y: '0.5rem',
    },
    carousel: {
      minImageHeight: '3rem',
      maxImageHeight: '5rem',
      maxImageWidth: '10rem',
    },
  },
  fontWeights: {
    extraStrong: '900',
    strong: '700',
    semiStrong: '500',
    regular: '400',
    semiRegular: '300',
    light: '100',
  },
  font: {
    _: {
      footnote: {
        lineHeight: '1rem',
        size: '0.625rem',
      },
      h1: {
        lineHeight: '1.75rem',
        size: '1.5rem',
      },
      h2: {
        lineHeight: '1.5rem',
        size: '1.25rem',
      },
      h3: {
        lineHeight: '1.25rem',
        size: '1.125rem',
      },
      h4: {
        lineHeight: '1.125rem',
        size: '1rem',
      },
      p: {
        lineHeight: '1.125rem',
        size: '1rem',
      },
      menu: {
        lineHeight: '1.125rem',
        size: '1rem',
      },
    },
    sm: {
      footnote: {
        lineHeight: '1rem',
        size: '0.8rem',
      },
      h1: {
        lineHeight: '2.75rem',
        size: '2.5rem',
      },
      h2: {
        lineHeight: '2.5rem',
        size: '2rem',
      },
      h3: {
        lineHeight: '2rem',
        size: '1.75rem',
      },
      h4: {
        lineHeight: '1.75rem',
        size: '1.5rem',
      },
      p: {
        lineHeight: '1.5rem',
        size: '1.125rem',
      },
      menu: {
        lineHeight: '1.5rem',
        size: '1.125rem',
      },
    },
    md: {
      footnote: {
        lineHeight: '1rem',
        size: '0.8rem',
      },
      h1: {
        lineHeight: '3rem',
        size: '2.5rem',
      },
      h2: {
        lineHeight: '2.5rem',
        size: '2rem',
      },
      h3: {
        lineHeight: '2rem',
        size: '1.75rem',
      },
      h4: {
        lineHeight: '2rem',
        size: '1.5rem',
      },
      p: {
        lineHeight: '1.5rem',
        size: '1.125rem',
      },
      menu: {
        lineHeight: '1.5rem',
        size: '1.125rem',
      },
    },
  },
}

export const globalStyle = `
  ${fontStyle}

  html, button {
    font-family: "Montserrat";
    font-weight: ${theme.fontWeights.regular};
  }
`

export default theme
