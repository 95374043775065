import Montserrat100WOFF from './montserrat-v12-latin-100.woff'
import Montserrat100WOFF2 from './montserrat-v12-latin-100.woff2'
import Montserrat300WOFF from './montserrat-v12-latin-300.woff'
import Montserrat300WOFF2 from './montserrat-v12-latin-300.woff2'
import Montserrat500WOFF from './montserrat-v12-latin-500.woff'
import Montserrat500WOFF2 from './montserrat-v12-latin-500.woff2'
import Montserrat700WOFF from './montserrat-v12-latin-700.woff'
import Montserrat700WOFF2 from './montserrat-v12-latin-700.woff2'
import Montserrat900WOFF from './montserrat-v12-latin-900.woff'
import Montserrat900WOFF2 from './montserrat-v12-latin-900.woff2'

export default {
  Montserrat100WOFF,
  Montserrat100WOFF2,
  Montserrat300WOFF,
  Montserrat300WOFF2,
  Montserrat500WOFF,
  Montserrat500WOFF2,
  Montserrat700WOFF,
  Montserrat700WOFF2,
  Montserrat900WOFF,
  Montserrat900WOFF2,
}
