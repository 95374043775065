import styled from 'styled-components'
import { grid } from 'styled-components-grid'

export default styled.div`
  ${grid.unit({
    size: 1,
    visible: {
      xs: true,
      lg: false,
    },
  })}

  text-align: center;
  height: 0px;
  overflow: hidden;
  transition: height 0.3s;
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  padding: 0;
  display: block;
  max-width: 100%;
  background-color: ${props => props.theme.colors.navbar.other};

  font-size: ${props => props.theme.font._.menu.size};
  line-height: ${props => props.theme.font._.menu.lineHeight};

  ${props => props.theme.media.sm`
    font-size: ${props => props.theme.font.sm.menu.size};
    line-height: ${props => props.theme.font.sm.menu.lineHeight};
  `};

  ul {
    background-color: ${props => props.theme.colors.navbar.background};
    height: auto;
    padding: ${props => props.theme.dimen.main.x};

    li {
      margin: 25px auto 0;
      padding: 0 0 25px 0;
      max-width: 272px;

      a {
        text-decoration: none;
        color: ${props => props.theme.colors.navbar.link};
        cursor: pointer;
        height: 50px;

        :hover {
          font-weight: ${props => props.theme.fontWeights.strong};
        }
      }

      a.active {
        color: ${props => props.theme.colors.navbar.link};
        font-weight: ${props => props.theme.fontWeights.strong};
      }

      &:not(:nth-last-of-type(-n + 2)) {
        border-bottom: 1px solid rgba(160, 160, 160, 0.3);
      }
    }

    .link-button {
      border: 2px solid ${props => props.theme.colors.foreground('action')};
      background-color: ${props => props.theme.colors.background('action')};
      padding: 19px 50px;
      margin-top: 12px;

      a {
        display: inline-block;
        height: 100%;
        margin: 0;
        font-weight: ${props => props.theme.fontWeights.strong};
        color: ${props => props.theme.colors.foreground('action')};
        transition: all 0.15s ease-in;
      }

      &:hover {
        cursor: pointer;
        background-color: ${props =>
          props.theme.colors.background('actionHover')};
        a {
          color: ${props => props.theme.colors.foreground('actionHover')};
        }
      }
    }
  }

  &.isOpen {
    height: 100%;
  }
`
