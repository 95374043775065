import { grid } from 'styled-components-grid'
import styled from 'styled-components'

export default styled.div`
  ${grid.unit({
    size: {
      xs: 1,
      lg: 6 / 12,
    },
  })}

  padding-bottom: ${props => props.theme.dimen.line.y};

  font-size: ${props => props.theme.font._.p.size};
  line-height: ${props => props.theme.font._.p.lineHeight};

  color: ${props => props.theme.colors.footer.text};

  ${props => props.theme.media.sm`
    font-size: ${props => props.theme.font.sm.p.size};
    line-height: ${props => props.theme.font.sm.p.lineHeight};
    p {
      max-width: 85%;
    }
  `};

  ${props => props.theme.media.md`
    font-size: ${props => props.theme.font.md.p.size};
    line-height: ${props => props.theme.font.md.p.lineHeight};
  `};

  .lead {
    padding-bottom: 48px;
  }

  div {
    ${props => props.theme.media.xs`
      display: block;
    `}
    ${props => props.theme.media.md`
      display: flex;
    `}

    section {
      top: 0;
      padding-top: ${props => props.theme.dimen.line.y};
      padding-bottom: ${props => props.theme.dimen.line.y};

      h2 {
        color: ${props => props.theme.colors.footer.highlight};
        font-weight: ${props => props.theme.fontWeights.strong};
        padding-bottom: 18px;
      }

      width: 100%;
      ${props => props.theme.media.md`
        width: 50%;
      `}
    }
  }
`
