import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import reset from 'styled-reset'
import materialIcons from 'material-design-icons/iconfont/material-icons.css'

import { graphql } from 'gatsby'

import parameters from '@parameters'
//import materialIcons from 'material-design-icons/iconfont/material-icons.css'

import Header from '@components/header'
import Footer from '@components/footer'
import SEO from '@components/seo'

import headerLogo from '@svgs/logo.svg'
import footerLogo from '@svgs/logo-white.svg'

import theme, { globalStyle } from '@themes/neo'

/*${materialIcons}*/

const GlobalStyle = createGlobalStyle`
  ${reset}

  ${materialIcons}
  ${globalStyle}
`

const setupMathJax = w => {
  if (w.MathJax && w.MathJax.Hub && w.MathJax.Hub.Config) {
    w.MathJax.Hub.Config({
      TeX: {
        equationNumbers: {
          autoNumber: 'AMS',
        },
      },
      tex2jax: {
        inlineMath: [['$', '$']],
        displayMath: [['$$', '$$']],
        processEscapes: true,
      },
    })
    w.MathJax.Hub.Queue(['Typeset', w.MathJax.Hub])
  } else {
    w.MathJax = {
      tex2jax: {
        inlineMath: [['$', '$']],
        displayMath: [['$$', '$$']],
        processEscapes: true,
        processEnvironments: true,
        skipTags: ['script', 'noscript', 'style', 'textarea', 'pre'],
        TeX: {
          equationNumbers: { autoNumber: 'AMS' },
          extensions: ['AMSmath.js', 'AMSsymbols.js', 'color.js'],
        },
      },
    }
  }
}

export default class Layout extends Component {
  componentDidMount() {
    setupMathJax(window)
  }

  render() {
    const {
      children,
      copyright,
      description,
      footer,
      header,
      image,
      keywords,
      organization,
      pathname,
      title,
      type,
    } = this.props

    return (
      <Fragment>
        <SEO
          description={description}
          image={image}
          keywords={keywords}
          pathname={pathname}
          title={title}
          type={type}
        />
        <Helmet>
          <script
            src="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js?config=TeX-MML-AM_CHTML"
            async
          />
        </Helmet>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <Fragment>
            <Header
              company={
                (organization && organization.name) ||
                (parameters.organization && parameters.organization.name)
              }
              links={
                (header && header.links) ||
                (parameters.links && parameters.links.header)
              }
              logo={(header && header.logo) || headerLogo}
            />
            <section>
              {React.Children.map(children, child =>
                React.cloneElement(child, { title, keywords })
              )}
            </section>
            <Footer
              address={
                (organization && organization.address) ||
                (parameters.organization && parameters.organization.address)
              }
              city={
                (organization && organization.city) ||
                (parameters.organization && parameters.organization.city)
              }
              company={
                (organization && organization.name) ||
                (parameters.organization && parameters.organization.name)
              }
              copyright={copyright || parameters.copyright}
              country={
                (organization && organization.country) ||
                (parameters.organization && parameters.organization.country)
              }
              email={
                (organization && organization.email) ||
                (parameters.organization && parameters.organization.email)
              }
              headline={
                (footer && footer.headline) ||
                (parameters.footer && parameters.footer.headline)
              }
              lead={
                (footer && footer.lead) ||
                (parameters.footer && parameters.footer.lead)
              }
              links={
                (footer && footer.links) ||
                (parameters.links && parameters.links.footer)
              }
              logo={(footer && footer.logo) || footerLogo}
              postalcode={
                (organization && organization.postalcode) ||
                (parameters.organization && parameters.organization.postalcode)
              }
            />
          </Fragment>
        </ThemeProvider>
      </Fragment>
    )
  }
}

export const query = graphql`
  fragment LayoutFragment on MarkdownRemark {
    frontmatter {
      date
      title
      summary
      keywords
      type
      previewImage {
        childImageSharp {
          general: fixed(width: 1200, height: 1200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`
