import { grid } from 'styled-components-grid'
import styled from 'styled-components'

export default styled.div`
  ${grid.unit({
    size: {
      xs: 1 / 5,
      lg: 4 / 5,
    },
    visible: {
      xs: true,
      lg: false,
    },
  })}

  height: 100%;
  display: block;
  justify-content: flex-end;
  align-items: center;
`
