import { grid } from 'styled-components-grid'
import styled from 'styled-components'

export default styled.div`
  ${grid.unit({
    size: {
      xs: 4 / 5,
      md: 1 / 5,
    },
  })}

  img {
    max-height: ${props => props.theme.logo.minHeight};
    min-height: ${props => props.theme.logo.minHeight};
  }

  ${props => props.theme.media.lg`
    img {
      max-height: ${props => props.theme.logo.maxHeight};
    }
  `}
`
