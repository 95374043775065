import { grid } from 'styled-components-grid'
import styled from 'styled-components'

export default styled.div`
  ${grid.unit({
    size: {
      xs: 1,
      sm: 1 / 5,
    },
    visible: {
      xs: false,
      sm: true,
    },
  })}

  min-height: ${props => props.theme.font._.footnote.size};
  ${props => props.theme.media.sm`
    min-height: ${props => props.theme.font.sm.footnote.size};
  `}

  background: url(${props => props.src}) left bottom/contain no-repeat;
`
